<template>
  <sgv-table
    :items="views"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.name">
          <router-link
            class="text-decoration-none"
            :class="{'text-danger': !item.isActive}"
            :to="toDetail(item.id)">
            {{item.name}}
          </router-link>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`kioskView:${kioskViewType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_VIEW } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    kioskViewType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `KioskView${this.$form.capitalize(this.kioskViewType)}Detail`,
      rKey: `KioskView${this.$form.capitalize(this.kioskViewType)}ListTable`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name'],
        column: null,
        search: null,
        toolbar: null,
      },
      views: [],
    }
  },
  apollo: {
    views: {
      query () {
        return LIST_VIEW(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          kioskViewType: this.kioskViewType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {kioskViewId: id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
